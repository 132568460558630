import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PartnerDetails = () => {
	const { partnerName } = useParams();
	const [partners, setPartners] = useState([]);
	const [partner, setPartner] = useState({});

    console.log(partnerName);

	useEffect(() => {
		axios.get('./partner_data.json')
			.then(response => {
				setPartners(response.data);
				const selectedPartner = response.data.find(p => p['partner-name'] === partnerName);
				setPartner(selectedPartner || {});
			})
			.catch(error => {
				console.error(error);
			});
	}, [partnerName]);

	return (

		<div className='container partner-details'>
            <h5>Seite ist in arbeit.</h5>
            <h4>{partner['partner-name']}</h4>
            <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                <strong>Details</strong>
                </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                    <table className="table">
                        <tbody>
                        {Object.entries(partner.details || {}).map(([key, value]) => (
                            <tr key={key}>
                            <td>{key}</td>
                            <td>{value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        <strong>Kompetenzen</strong>
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <table className="table">
            <tbody>
            <tr></tr>               
            {/*
            {Object.entries(partner.kompetenzen || {}).map(([key, value]) => (
            
                <tr key={key}>
                <td rowSpan={value.Hersteller.length + 1}>{key}</td>
                </tr>
                
                
 
          ))}
          */}
          {Object.entries(partner.kompetenzen || {}).map(([key, value]) => (
            <>
              <tr key={`${key}-Hersteller`}>
                <td>Hersteller</td>
                <td>{value.Hersteller.join(', ')}</td>
              </tr>
              <tr key={`${key}-Systeme`}>
                <td>Systeme</td>
                <td>{value.Systeme.join(', ')}</td>
          </tr>
            </>
         ))}
         {/*<tr>
            <td>Hersteller A</td>
            <td>System A</td>
         </tr>
         <tr>
            <td>Hersteller B</td>
            <td>System B</td>
        </tr>*/}

            </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        Besonderheiten
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        
      </div>
    </div>
  </div>
</div>
            

		</div>
	);
};

export default PartnerDetails;
