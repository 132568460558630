import React from 'react';
import logo from './logo.png';
import './font.css';
import './fonts/Lato-Regular.woff';
import './fonts/Lato-Regular.woff2';
import './fonts/Lato-Light.woff';
import './fonts/Lato-Light.woff2';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Nav = () => (
    <header>
    <Container className="p-3">
  
    <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
  		<Link to="/" className='navbar-brand'><img src={logo} width={195} height={112} alt="Logo"/></Link>
  		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    		<span className="navbar-toggler-icon"></span>
  		</button>
		<div className="collapse navbar-collapse" id="navbarNav">
			<ul className="navbar-nav">
				<li className="nav-item active">
					<Link to="/Competence-Team" className='nav-link'>Competence Team</Link>
				</li>
				<li className="nav-item">
				<Link to="/Standorte" className='nav-link'>Standorte</Link>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="/Kontakt">Kontakt</a>
				</li>
			</ul>
		</div>
  
</nav>
</Container>
</header>
)
export default Nav;