import React, { useState } from "react";

const Contact = () => {
  const [formStatus, setFormStatus] = useState("Senden");
  const [selectedOption1, setSelectedOption1] = useState("option1");
  const [selectedOption2, setSelectedOption2] = useState("option2");
  const [vorname, setVorname] = useState("");
  const [firma, setFirma] = useState("");
  const [nachname, setNachname] = useState("");
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [nachricht, setNachricht] = useState("");
  const [honeypot, setHoneypot] = useState("");
  

  const handleOptionChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };
  const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormStatus("Submitting...");

    try {
      const data = {
        anliegen: selectedOption1,
        kontakt: selectedOption2,
        vorname: vorname,
        nachname: nachname,
        firma: firma,
        email: email,
        telefon: telefon,
        nachricht: nachricht,
        anrede: honeypot
      };

      const response = await fetch("data.php", {
        method: "POST",
        body: JSON.stringify(data),
        data: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });
      if (response.ok) {
        setFormStatus("Sent Successfully!");
      } else {
        setFormStatus("Error Sending Form");
      }
    } catch (error) {
      setFormStatus("Error Sending Form");
    }
  };

  return (
    <div className="mt-5 container-sm">
      <h3 className="mb-3">Kontaktdaten</h3>

      <form onSubmit={handleSubmit}>

        <div className="mb-3" id="firma">
          <label className="form-label" htmlFor="firma">
            Firma
          </label>
          <input className="form-control" type="text" id="firma" required name="firma" value={firma} onChange={(e) => setFirma(e.target.value)} />
        </div>
        <div className="mb-3" id="anrede">
          <label className="form-label" htmlFor="anrede">
            Anrede
          </label>
          <input className="form-control" type="text" id="anrede" name="anrede" value={honeypot} onChange={(e) => setHoneypot(e.target.value)} />
        </div>
        <div className="mb-3" id="vorname">
          <label className="form-label" htmlFor="vorname">
            Vorname
          </label>
          <input className="form-control" type="text" id="vorname" required  name="vorname" value={vorname} onChange={(e) => setVorname(e.target.value)}/>
        </div>
        <div className="mb-3" id="nachname">
          <label className="form-label" htmlFor="nachname">
            Nachname
          </label>
          <input className="form-control" type="text" id="nachname" required name="nachname" value={nachname}  onChange={(e) => setNachname(e.target.value)}/>
        </div>
        <div className="mb-3" id="email">
          <label className="form-label" htmlFor="email">
            E-Mail
          </label>
          <input className="form-control" type="email" id="email" required name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className="mb-3" id="telefon">
          <label className="form-label" htmlFor="telefon">
          Telefon
          </label>
          <input className="form-control" type="tel" id="telefon" name="telefon" value={telefon} onChange={(e) => setTelefon(e.target.value)}/>
        </div>
        <h3 className='mb-3'>Anliegen</h3>
        <div className="form-check" id="anliegen">
        <label>
        <input
          className="form-check-input"
          type="radio"
          value="interesse-mitgliedschaft"
          checked={selectedOption1 === "interesse-mitgliedschaft"}
          onChange={handleOptionChange1}
          name="anliegen"
        />
        Ich interessiere mich für eine Mitgliedschaft im cc4i
      </label>
      </div>
      <div className="form-check">
      <label>
        <input
          className="form-check-input"
          type="radio"
          value="unterstützung-projekt"
          checked={selectedOption1 === "unterstützung-projekt"}
          onChange={handleOptionChange1}
          name="anliegen"
        />
        Ich brauche Unterstützung in einem innovaphone Projekt
      </label>
      </div>
        <div className="mb-3" id="nachricht">
          <label className="form-label" htmlFor="message">
            Beschreibung
          </label>
          <textarea className="form-control" id="message" required placeholder="Ihr Anliegen" cols={4} rows={7} name="nachricht" value={nachricht}  onChange={(e) => setNachricht(e.target.value)}/>
        </div>
        <h3 className='mb-3'>Kontaktaufnahme</h3>
        <div className="form-check">
        <label>
        <input
          className="form-check-input"
          type="radio"
          value="kontakt-telefon"
          checked={selectedOption2 === "kontakt-telefon"}
          onChange={handleOptionChange2}
          name="kontakt"/>
        Per Telefon
      </label>
      </div>
        <div className="form-check">
        <label>
        <input
          className="form-check-input"
          type="radio"
          value="kontakt-email"
          checked={selectedOption2 === "kontakt-email"}
          onChange={handleOptionChange2}
          name="kontakt"
        />
        Per E-Mail
      </label>
      </div>

        <button className="btn btn-primary btn-contact" type="submit">
          {formStatus}
        </button>
      </form>
    </div>
  )
}
export default Contact