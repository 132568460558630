import React from 'react';


import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faCog, faRocket } from '@fortawesome/free-solid-svg-icons';
const Home = () => (
	// eslint-disable-next-line
    <home>
    <section className="hero">
	<div className="container">
		<div className="row">
			<div className="col-md-12">
				<div id="c52" className="m-b-1">
					<div className="ce-textpic ce-center ce-above">
						<div className="ce-bodytext">
							<h1><span className="detail">Competence Center für innovaphone</span></h1>
							<p>Perfekt auf innovaphone abgestimmt. Basierend auf mehr als 10 Jahren Erfahrung.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<Container className="p-3">
	<div className="col-md-12">
		<div id="cards" className="m-t-3">
			<div className="row">
				<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
					<div className="nowell ">
						<div id="c732" className="">
							<div className="media">
								<div className="media-left">
									<FontAwesomeIcon icon={faImage} className="fa"/>
									<i className="fa fa-picture-o"></i>
								</div>
								<div className="media-body">
									<div id="c134" className="">
										<h3>Full IP!</h3>
										<div className="ce-textpic ce-center ce-above">
											<div className="ce-bodytext">
												<p>"Mobile first" lautet das Motto von innovaphone. Die Integration von Mobil-Geräten, Filialen und Home&nbsp;Offices steht an erster Stelle. Wir zeigen Ihnen wie IT und TK sicher und verlässlich zusammenwachsen.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
					<div className="nowell ">
						<div id="c733" className="">
							<div className="media">
								<div className="media-left">
        							<FontAwesomeIcon icon={faCog} className="fa"/>
                    				<i className="fa fa-cog"></i>
								</div>
								<div className="media-body">
									<div id="c77" className="">
										<h3>Für Administratoren: Flexibel erweiterbar!</h3>
										<div className="ce-textpic ce-center ce-above">
											<div className="ce-bodytext">
												<p>Der Vorteil von innovaphone liegt in der Mächtigkeit der VoIP und IT-Standards auf denen die innovaphone Produkte basieren. </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
		</div>
	<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
		<div className="nowell ">
			<div id="c734" className="">
				<div className="media">
					<div className="media-left">
      					<FontAwesomeIcon icon={faRocket} className="fa"/>
                    	<i className="fa fa-rocket"></i>
					</div>
					<div className="media-body">
						<div id="c132" className="">
							<h3>Anwendungen</h3>
								<div className="ce-textpic ce-center ce-above">
									<div className="ce-bodytext">
										<p>Das innovaphone-System kommt mit fertiger Software für Kontakte, Reporting, Operatoren und Mobilgeräte. Offene Standards wie H.323, WebRTC. LDAP und SIP ermöglichen andere Komponenten einzugliedern.</p>
									</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
	<div id="c933" className="">
		<h2>Unser Statement</h2>
			<div className="ce-textpic ce-right ce-intext">
				<div className="ce-bodytext">
					<p>Das Competence Center for innovaphone, kurz <a href="de/competence-team/" title="Competence Team" className="internal-link"><strong>cc4i</strong></a>, ist ein Zusammenschluss von engagierten Systemhäusern, die seit vielen Jahren umfangreiches Know How mit der innovaphone Technologie aufgebaut und viele erfolgreiche Projekte damit umgesetzt haben. </p>
					<p>Alle <strong><a href="de/competence-team/" title="Competence Team" className="internal-link">cc4i-Partner</a></strong> sind inhabergeführte mittelständische Unternehmen. Wir ergänzen uns durch verschiedene Schwerpunkte in unseren Geschäftstätigkeiten und Portfolios und verfügen dadurch über ein breites Spektrum an Technologien. </p>
					<p>Unsere Philosophie des offenen, kooperativen und vertrauensvollen Miteinanders bildet die Grundlage der Zusammenarbeit. Durch den regelmäßigen Austausch unserer Erfahrung und unseres Wissen untereinander verfügen wir über eine hohe Lösungskompetenz. </p>
					<p>Das <a href="de/competence-team/" title="Competence Team" className="internal-link">Competence Team</a> <strong><a href="de/competence-team/" title="Competence Team" className="internal-link">cc4i</a></strong> ist ein starkes Bündnis, das auch überregionale innovaphone Projekte kompetent und zuverlässig durchführen kann. Die Mehrwerte für unsere Kunden entstehen durch die überregionale Verteilung der Mitglieder, kompetente Ansprechpartner vor Ort und einen großen Pool an Systemspezialisten. </p>
					<p>&nbsp;</p><p>&nbsp;</p>
				</div>
			</div>
	</div>
</div>
</Container>

</home>
)
export default Home;