import React from 'react';
import './font.css';
import './fonts/Lato-Regular.woff';
import './fonts/Lato-Regular.woff2';
import './fonts/Lato-Light.woff';
import './fonts/Lato-Light.woff2';
import {
    Container
} from 'react-bootstrap';

const Impressum = () => ( 
<Container >
    <p></p>
    <h3>Impressum</h3> 
    
    <p>Verantwortlich nach TMG<br/><br/>

    Competence Center for innovaphone<br/> vertreten durch<br/><br/>

    Carsten Jenner<br/>
    c/o Syncope GmbH<br/>
    Heidenkampsweg 32<br/>
    20097 Hamburg<br/>
    Tel +49 40 251545 0<br/>
    Fax +49 40 251545 99<br/>
    Mail office@syncope.de<br/></p>
</Container>
)
export default Impressum;