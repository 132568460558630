import React, { useEffect, useRef } from 'react';
import './font.css';
import './standorte.css';
import './fonts/Lato-Regular.woff';
import './fonts/Lato-Regular.woff2';
import './fonts/Lato-Light.woff';
import './fonts/Lato-Light.woff2';
import { Container } from 'react-bootstrap';
import Cookies from 'js-cookie';

function Standorte() {
  const iframeRef = useRef(null);
  const captionRef = useRef(null);

  useEffect(() => {
    captionRef.current = document.querySelector('.caption');
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    // Get the value of the 'data-src' attribute from the iframe
    const dataSrc = iframeRef.current.getAttribute('data-src');
    iframeRef.current.setAttribute('src', dataSrc);
    captionRef.current.setAttribute('style', 'display: none');
    iframeRef.current.setAttribute('style', 'display: block');
    //setIframeSrc(dataSrc);
    Cookies.set('consentOSM', 'true', { expires: 7 });
  }
  function checkCookie() {
    const cookie = Cookies.get("consentOSM");
    if (!cookie) {
      console.log("Cookie is not set.")
      iframeRef.current.setAttribute('style', 'display: none');
    }
    else {
      captionRef.current.setAttribute('style', 'display: none');
      const dataSrc = iframeRef.current.getAttribute('data-src');
      iframeRef.current.setAttribute('src', dataSrc);
      iframeRef.current.setAttribute('style', 'display: block');
    }
  }

  useEffect(() => {
    checkCookie();
  }, []);

  return (
    <Container>
      <br></br>
      <div className="consent">
        <div className="caption" ref={captionRef}>
          <p>Durch das klicken der Schaltfläche akzeptieren Sie unsere <a href="/datenschutz">Datenschutzerklärung</a> und die von OpenStreetMaps.<br/><a href="https://www.fossgis.de/datenschutzerkl%C3%A4rung/" target={"_blank"}>Mehr erfahren</a></p>
          <button className="btn btn-primary" onClick={handleClick}>Akzeptieren</button>
        </div>
        <iframe ref={iframeRef} width="100%" height="900px" frameBorder="0" allowFullScreen title='osm' data-src="//umap.openstreetmap.de/de/map/cc4i_33987?scaleControl=false&miniMap=false&scrollWheelZoom=false&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false"></iframe>
        <div className='fs'>
          <p><a href="//umap.openstreetmap.de/de/map/cc4i_33987">Vollbildanzeige</a></p>
        </div>
      </div>
    </Container>
  );
}

export default Standorte;
