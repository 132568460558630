import React, { useState, useEffect } from "react";


import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faEnvelope, faPhone, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

function CompetenceTeam() {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    axios.get('/partners/partner_data.json')
      .then(response => setPartners(response.data))
      .catch(error => console.log(error));
  }, []);

  return (
    <div className="container cc4i-container"><br/>
    <div className="row">
      {partners.map(partner => (
        <div className="col-sm-3 col-md-6 col-lg-4 col-xl-4 col-xxl-3" key={partner['partner-name']}>
          <div className="card cc4i-card">
            <div className="card-body">
              <h4 className="card-title">{partner['partner-name']}</h4>
              <p className="card-text">{partner['partner-location']}</p>
              {partner['partner-slogan'] && (
                <p className="card-text">{partner['partner-slogan']}</p>
              )}
              {partner.web && (<p><FontAwesomeIcon icon={faLink} className="fa cc4i-svg"/><a href={`https://${partner.web}`} className="card-link" target={"_blank"}>Website</a></p>)}
              
              {partner.mail && (
              <p><FontAwesomeIcon icon={faEnvelope} className="fa cc4i-svg"/><a href={`mailto:${partner.mail}`} className="card-link">E-Mail</a></p>
              )}
              <p><FontAwesomeIcon icon={faPhone} className="fa cc4i-svg"/><a href={`tel:${partner.tel}`} className="card-link">Telefon</a></p>
              {/* {partner.details && (<p><FontAwesomeIcon icon={faCircleInfo} className="fa cc4i-svg"/><a href={`/partners/${partner['partner-name']}`} className="card-link">mehr Informationen</a></p>)} */}
            </div>
          </div>
        </div>

      ))}
      <div class="w-100"></div>
    </div>
    </div>
  );
}

export default CompetenceTeam;