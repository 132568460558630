//packages
import React, {useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";
//css
import './App.css';
//fonts
import './fonts/Lato-Regular.woff';
import './fonts/Lato-Regular.woff2';
import './fonts/Lato-Light.woff';
import './fonts/Lato-Light.woff2';
// sites
import CompetenceTeam from './CompetenceTeam';
import Contact from './Contact';
import Standorte from './Standorte';
import Home from './Home';
import Nav from './Nav';
import Footer from './Footer';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import PartnerDetails from './PartnerDetails';


function App() {
	const [accepted, setAccepted] = useState(false);
	const [optionalAccepted, setOptionalAccepted] = useState(false);
	
	function handleAccept() {
		setAccepted(true);
		
		Cookies.set("CookieConsent", "true", { path: "/" });
		if (optionalAccepted) {
			Cookies.set("consentOSM", "true", { path: "/" });
		}
		window.location.reload();
	  }
	  
	  return (
<main>
  <main className="main-content" id="content" role="main">
  <CookieConsent
		location="bottom"
		buttonText="Akzeptieren"
		style={{ background: "#2B373B" }}
		buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
		expires={150}
		enableDeclineButton
		// onDecline={() => {
		// 	alert("nay!");
		//   }}
		  declineButtonText="Ablehnen"
		  flipButtons
		  onAccept={handleAccept}
		>
		Diese Webseite verwendet Cookies, um Ihnen die bestmögliche Nutzererfahrung zu bieten.&nbsp; 
		<a href="/privacy-policy">Mehr erfahren</a><br/>
		<label>
          <input
            type="checkbox"
            checked={optionalAccepted}
            onChange={e => setOptionalAccepted(e.target.checked)}
          />
          Accept optional cookie
        </label>
		</CookieConsent>
	<Nav/>
	<Routes>
		<Route path='/' element={<Home/>}/>
		<Route path="/competence-team" element={<CompetenceTeam />}/>
		<Route path="/kontakt" element={<Contact />}/>
		<Route path="/standorte" element={<Standorte />}/>
		<Route path="/datenschutz" element={<Datenschutz />}/>
		<Route path="/impressum" element={<Impressum />}/>
		<Route path="/partners/:partnerName" element={<PartnerDetails />} />
	</Routes>
	
    
	
  </main>
  <Footer/>
</main>
	  )
};

export default App;
