import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
    <footer className="footer mt-auto py-3 bg-light fixed-bottom">
        <div className="container">
            <div className="text-center">
            <Link to="/impressum">Impressum</Link>&nbsp;
            <Link to="/datenschutz">Datenschutzerklärung</Link>&nbsp;
            
            </div>
</div>
</footer>
)
export default Footer;

